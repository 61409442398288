<template>
    <div class="tabs">
        <div class="item" :class="{on: type === 1}" @click="goRouter(1, '/shop/keep')">
            <span>收藏的商品</span>
        </div>
        <div class="item" :class="{on: type === 2}" @click="goRouter(2, '/shop/keep/store')">
            <span>收藏的店铺</span>
        </div>
    </div>
</template>

<script>
  export default {
    name: "shopKeepMenu",
    props: {
      type: {
        type: Number,
        default: 1, // 1-商品，2-店铺
      }
    },
    methods: {
      goRouter (type, url) {
        if (this.type !== type) {
          this.$router.replace(url)
        }
      }
    }
  }
</script>
